import { FC } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  weight: 'light' | 'regular' | 'heavy'
}

export const AvatarInnerBorder: FC<Props> = ({ weight }) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.light]: weight === 'light',
        [styles.regular]: weight === 'regular',
        [styles.heavy]: weight === 'heavy',
      })}
    />
  )
}
