import { FC, ReactNode } from 'react'
import { useStores } from '@/utils'
import { DropDownListViewerHeader } from '@components-v2/molecules/DropDownListViewerHeader'
import { DropDownListItemDivider } from '@components-v2/atoms/DropDownListItemDivider'
import { DropDownListItemGroupContainer } from '@components-v2/molecules/DropDownListItemGroupContainer'
import { DropDownListItems } from '@components-v2/molecules/DropDownListItems'
import { DropDownListItem } from '@components-v2/atoms/DropDownListItem'
import UserSvg from '@/public/images/common/user-01_v2.svg'
import MessageSvg from '@/public/images/common/message-dots-circle_v2.svg'
import SettingSvg from '@/public/images/common/settings-01_v2.svg'
import { useTranslation } from 'next-i18next'
import PlusSvg from '@/public/images/common/plus-circle_v2.svg'
import LogOutSvg from '@/public/images/common/log-out-01_v2.svg'
import { DropDownListTextHeader } from '@components-v2/molecules/DropDownListTextHeader'
import { DropDownListCompanyHeaders } from '@components-v2/molecules/DropDownListCompanyHeaders'
import { MessageType } from '@/types'
import { observer } from 'mobx-react'
import { DropdownMenu } from '@components-v2/atoms/DropdownMenu'
import { DropDownListPmsMenu } from '@/componentsV2/molecules/DropDownListPmsMenu'
import styles from './index.module.scss'

type MenuItem = {
  iconSvg: ReactNode
  text: string
  href: string
}

type Props = {
  toggleDropDown: () => void
}

export const GlobalDesktopHeaderAccountMenuDropdown: FC<Props> = observer(({ toggleDropDown }) => {
  const { t } = useTranslation(['global'])
  const { messages, viewer } = useStores()
  const personalMenuItems: MenuItem[] = [
    {
      text: t('プロフィール'),
      href: '/account/profile',
      iconSvg: <UserSvg viewBox='0 0 24 24' />,
    },
    {
      text: t('設定'),
      href: '/account/settings',
      iconSvg: <SettingSvg viewBox='0 0 24 24' />,
    },
    {
      text: t('メッセージ一覧'),
      href: '/messages',
      iconSvg: <MessageSvg viewBox='0 0 24 24' />,
    },
  ]
  const signOut = async () => {
    const isSuccessful = await viewer.signOut()
    if (isSuccessful) {
      messages.add({
        type: MessageType.Info,
        body: t('ログアウトしました。'),
        isTranslated: true,
        ttl: 5000,
      })
      // ログアウト後、確実にメモリをクリアするためにリロードさせる
      window.location.href = '/signin'
    }
  }

  return (
    <DropdownMenu toggleDropDown={toggleDropDown}>
      <div className={styles.container}>
        <div className={styles.header}>
          <DropDownListViewerHeader viewer={viewer?.viewer} />
          <DropDownListItemDivider />
        </div>
        <div className={styles.content}>
          <DropDownListItemGroupContainer>
            <DropDownListTextHeader>{t('パーソナル')}</DropDownListTextHeader>
            <DropDownListItems items={personalMenuItems} />
          </DropDownListItemGroupContainer>
          <DropDownListItemDivider />

          <DropDownListItemGroupContainer>
            <DropDownListTextHeader>{t('会社')}</DropDownListTextHeader>
            <DropDownListCompanyHeaders companies={viewer.viewer?.companyMembers?.map((cm) => cm?.company)} />
            <DropDownListItem
              href='/companies/new'
              text={t('会社を新規作成')}
              iconNode={<PlusSvg viewBox='0 0 24 24' />}
            />
          </DropDownListItemGroupContainer>
          <DropDownListItemDivider />

          {viewer.viewer?.investorMembers?.length > 0 && (
            <>
              <DropDownListItemGroupContainer>
                <DropDownListTextHeader>{t('投資管理クラウド')}</DropDownListTextHeader>
                <DropDownListPmsMenu />
              </DropDownListItemGroupContainer>
              <DropDownListItemDivider />
            </>
          )}

          <DropDownListItemGroupContainer>
            <DropDownListItem
              type='button'
              text={t('ログアウト')}
              iconNode={<LogOutSvg viewBox='0 0 24 24' />}
              onClick={signOut}
            />
          </DropDownListItemGroupContainer>
          <DropDownListItemDivider />
        </div>
      </div>
    </DropdownMenu>
  )
})
