import { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import styles from './index.module.scss'

export const DesktopHeaderRightLanguageSelection: FC = () => {
  const router = useRouter()
  const [isReady, setIsReady] = useState(false)

  // router.query に値が入ったか確認
  useEffect(() => {
    if (router.isReady) {
      setIsReady(true)
    }
  }, [router.isReady])

  // 英語の場合は日本語切り替えのリンクを表示。日本語の場合は英語切り替えリンクを表示。
  const isEn = router.locale === 'en'
  return (
    <div className={styles.language}>
      {isReady && (
        <div className={styles.link} role='button' tabIndex={0}>
          {isEn ? <a href={`/ja${router.asPath}`}>日本語</a> : <a href={`/en${router.asPath}`}>English</a>}
        </div>
      )}
    </div>
  )
}
