import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { getSignInPath } from '@/utils'
import styles from './index.module.scss'

export const DesktopHeaderRightSignInGroup: FC = () => {
  const { t } = useTranslation(['global'])
  const router = useRouter()

  return (
    <div className={styles.container}>
      <button
        type='button'
        className={styles.signInLink}
        onClick={() => {
          const path = getSignInPath(router.asPath)
          router.push(path)
        }}
      >
        {t('ログイン')}
      </button>
      <button
        type='button'
        className={styles.signUpLink}
        onClick={() => {
          router.push('/signup')
        }}
      >
        {t('新規登録')}
      </button>
    </div>
  )
}
