import { FC } from 'react'
import classNames from 'classnames'
import { AvatarInnerBorder } from '@components-v2/atoms/AvatarInnerBorder'
import styles from './index.module.scss'

type Props = {
  src?: string
  alt?: string
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' // 2xl
  isFocused?: boolean
}

export const Avatar2: FC<Props> = ({ src, alt = '', size, isFocused = false }) => {
  const borderWeight = size === 'xs' || size === 'sm' ? 'light' : 'regular'
  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.xs]: size === 'xs',
          [styles.sm]: size === 'sm',
          [styles.md]: size === 'md',
          [styles.lg]: size === 'lg',
          [styles.xl]: size === 'xl',
          [styles.xxl]: size === 'xxl',
        },
        {
          [styles.focused]: isFocused,
        }
      )}
    >
      <img src={src} alt={alt} />
      <div className={styles.innerBorder}>
        <AvatarInnerBorder weight={borderWeight} />
      </div>
    </div>
  )
}
