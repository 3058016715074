import { FC } from 'react'
import { observer } from 'mobx-react'
import { getCompanyLogo, getUserAvatar, useStores } from '@/utils'
import { VehicleInvestor } from '@/lib/generated/sdk'
import { DropDownMenuItemWithImage } from '@/componentsV2/molecules/DropDownMenuItemWithImage'

export const DropDownListPmsMenu: FC = observer(() => {
  const { viewer } = useStores()
  return (
    <>
      {viewer.viewer?.investorMembers?.map((investorMember) => {
        let key = ''
        let src = ''
        let name = ''
        let href = ''
        if (investorMember.investor?.investorType === VehicleInvestor.USER) {
          key = investorMember.investor?.user?.name
          src = getUserAvatar(investorMember.investor?.user)
          name = investorMember.investor?.user?.name
          href = `/pms/u/${investorMember.investor?.investorSlug}`
        } else {
          key = investorMember.investor?.company?.name
          src = getCompanyLogo(investorMember.investor?.company)
          name = investorMember.investor?.company?.name
          href = `/pms/c/${investorMember.investor?.investorSlug}`
        }
        return <DropDownMenuItemWithImage key={key} imageSrc={src} label={name} href={href} />
      })}
    </>
  )
})
