import { FC, ReactNode } from 'react'
import { DropDownListItem } from '@components-v2/atoms/DropDownListItem'
import { observer } from 'mobx-react'

type MenuItem = {
  type?: 'link' | 'button'
  iconSvg?: ReactNode
  href?: string
  text: string
  isExternal?: boolean
  onClick?: () => void
}

type Props = {
  items: MenuItem[]
}

export const DropDownListItems: FC<Props> = observer(({ items }) => {
  return (
    <>
      {items.map((item) => (
        <DropDownListItem
          key={item.text}
          type={item.type ?? 'link'}
          iconNode={item.iconSvg}
          text={item.text}
          href={item.href}
          isExternal={item.isExternal}
          onClick={item.onClick}
        />
      ))}
    </>
  )
})
