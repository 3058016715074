import { FC, useState } from 'react'
import classNames from 'classnames'
import { OutSideOverlay } from '@components-v2/atoms/OutSideOverlay'
import MenuSvg from '@/public/images/common/menu-02_v2.svg'
import { GlobalMobileHeaderServiceMenuDropdown } from '@components-v2/organisms/GlobalMobileHeaderServiceMenuDropdown'
import { observer } from 'mobx-react'
import styles from './index.module.scss'

export const GlobalMobileHeaderServiceMenu: FC = observer(() => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropDown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={styles.container}>
      <button className={styles.button} type='button' onClick={toggleDropDown}>
        <div className={styles.button__icon}>
          <MenuSvg viewBox='0 0 24 24' />
        </div>
      </button>
      <div
        className={classNames(styles.menu, {
          [styles.activeMenu]: isOpen,
        })}
      >
        <GlobalMobileHeaderServiceMenuDropdown toggleDropDown={toggleDropDown} />
      </div>
      <OutSideOverlay onClick={toggleDropDown} isOpen={isOpen} />
    </div>
  )
})
