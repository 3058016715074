import { FC, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { DropDownListTextHeader } from '@components-v2/molecules/DropDownListTextHeader'
import { DropDownListItemGroupContainer } from '@components-v2/molecules/DropDownListItemGroupContainer'
import { DropDownListItems } from '@components-v2/molecules/DropDownListItems'
import { DropDownListItemDivider } from '@components-v2/atoms/DropDownListItemDivider'
import { LanguageSelection } from '@components-v2/molecules/LanguageSelection'
import UsersSvg from '@/public/images/common/users-01_v2.svg'
import BuildingSvg from '@/public/images/common/building-01_v2.svg'
import RocketSvg from '@/public/images/common/rocket-01_v2.svg'
import CoinsHandSvg from '@/public/images/common/coins-hand_v2.svg'
import PieChartSvg from '@/public/images/common/untitled-ui-icons/pie-chart-02.svg'
import GiftSvg from '@/public/images/common/untitled-ui-icons/gift-01.svg'
import BankNoteSvg from '@/public/images/common/untitled-ui-icons/bank-note-01.svg'
import BriefCaseSvg from '@/public/images/common/briefcase-01_v2.svg'
import MessageAlertSvg from '@/public/images/common/message-alert-circle_v2.svg'
import MessageSmileSvg from '@/public/images/common/untitled-ui-icons/message-smile-square.svg'
import GraduationHatSvg from '@/public/images/common/untitled-ui-icons/graduation-hat-02.svg'
import { DropdownMenu } from '@components-v2/atoms/DropdownMenu'
import styles from './index.module.scss'

type MenuItem = {
  text: string
  href: string
  iconSvg: ReactNode
  isExternal: boolean
}

type Props = {
  toggleDropDown: () => void
}

export const GlobalMobileHeaderServiceMenuDropdown: FC<Props> = observer(({ toggleDropDown }) => {
  const { t } = useTranslation(['global'])
  const router = useRouter()
  const isJa = router.locale === 'ja'
  const startupMenuItems: MenuItem[] = [
    {
      text: t('エンジェル投資家を探す'),
      href: '/angels',
      iconSvg: <UsersSvg viewBox='0 0 24 24' />,
      isExternal: false,
    },
    {
      text: t('ベンチャーキャピタルを探す'),
      href: '/vcs',
      iconSvg: <BuildingSvg viewBox='0 0 24 24' />,
      isExternal: false,
    },
    {
      text: t('M&Aで売却する'),
      href: `https://ma.protocol.ooo/${isJa ? '1' : '1-1'}`,
      iconSvg: <CoinsHandSvg viewBox='0 0 24 24' />,
      isExternal: true,
    },
  ]
  if (isJa) {
    startupMenuItems.push({
      text: t('会員限定特典'),
      href: 'https://prosupporters.protocol.ooo/',
      iconSvg: <GiftSvg viewBox='0 0 24 24' />,
      isExternal: true,
    })
    startupMenuItems.push({
      text: t('補助金申請支援'),
      href: 'https://prosupporters.protocol.ooo/itgrants',
      iconSvg: <BankNoteSvg viewBox='0 0 24 24' />,
      isExternal: true,
    })
    startupMenuItems.push({
      text: t('メンターを探す'),
      href: 'https://prosupporters.protocol.ooo/protocolmentor',
      iconSvg: <GraduationHatSvg viewBox='0 0 24 24' />,
      isExternal: true,
    })
  }
  startupMenuItems.push({
    text: t('グロースについて相談する'),
    href: isJa ? process.env.NEXT_PUBLIC_CONTACT_SALES_JA_URL : process.env.NEXT_PUBLIC_CONTACT_SALES_EN_URL,
    iconSvg: <MessageSmileSvg viewBox='0 0 24 24' />,
    isExternal: true,
  })
  const investorMenuItems: MenuItem[] = [
    {
      text: t('スタートアップを探す'),
      href: '/startups',
      iconSvg: <RocketSvg viewBox='0 0 24 24' />,
      isExternal: false,
    },
    {
      text: t('投資管理クラウドを使う（β版）'),
      href: 'https://prosupporters.protocol.ooo/investorcloud',
      iconSvg: <PieChartSvg viewBox='0 0 24 24' />,
      isExternal: true,
    },
  ]
  const jobSeekerMenuItems: MenuItem[] = [
    {
      text: t('求人を探す'),
      href: '/jobs',
      iconSvg: <BriefCaseSvg viewBox='0 0 24 24' />,
      isExternal: false,
    },
  ]

  const feedbackMenuItems: MenuItem[] = [
    {
      text: t('フィードバック'),
      href: process.env.NEXT_PUBLIC_SUPPORT_FORM_URL,
      iconSvg: <MessageAlertSvg viewBox='0 0 24 24' />,
      isExternal: true,
    },
  ]

  return (
    <DropdownMenu toggleDropDown={toggleDropDown}>
      <div className={styles.container}>
        <div className={styles.content}>
          <DropDownListItemGroupContainer>
            <DropDownListTextHeader>{t('スタートアップ')}</DropDownListTextHeader>
            <DropDownListItems items={startupMenuItems} />
          </DropDownListItemGroupContainer>
          <DropDownListItemDivider />

          <DropDownListItemGroupContainer>
            <DropDownListTextHeader>{t('投資家')}</DropDownListTextHeader>
            <DropDownListItems items={investorMenuItems} />
          </DropDownListItemGroupContainer>
          <DropDownListItemDivider />

          <DropDownListItemGroupContainer>
            <DropDownListTextHeader>{t('求職者')}</DropDownListTextHeader>
            <DropDownListItems items={jobSeekerMenuItems} />
          </DropDownListItemGroupContainer>
          <DropDownListItemDivider />

          <DropDownListItemGroupContainer>
            <DropDownListTextHeader>{t('Language')}</DropDownListTextHeader>
            <div className={styles.language}>
              <LanguageSelection />
            </div>
          </DropDownListItemGroupContainer>
          <DropDownListItemDivider />

          <DropDownListItemGroupContainer>
            <DropDownListItems items={feedbackMenuItems} />
          </DropDownListItemGroupContainer>
        </div>
      </div>
    </DropdownMenu>
  )
})
