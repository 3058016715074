import { FC } from 'react'
import { NavMenuSection } from '@components-v2/molecules/NavMenuSection'
import { GlobalDeskTopNavMenuItemChild, NavMenuItems } from '@components-v2/molecules/NavMenuItems'
import { observer } from 'mobx-react'
import styles from './index.module.scss'

export type GlobalDeskTopNavSubMenu = {
  name: string
  column?: number
  subMenuItems: GlobalDeskTopNavMenuItemChild[]
}

type Column = {
  key: string
  subMenus: GlobalDeskTopNavSubMenu[]
}

type Props = {
  isMultipleColumns: boolean
  subMenus: GlobalDeskTopNavSubMenu[]
}

export const GlobalDesktopHeaderNavMenuDropdown: FC<Props> = observer(({ subMenus, isMultipleColumns }) => {
  // シングルカラムの場合
  if (!isMultipleColumns) {
    return (
      <div className={styles.container}>
        <div className={styles.column}>
          {subMenus.map((subMenu) => (
            <NavMenuSection key={subMenu.name} title={subMenu.name}>
              <div className={styles.itemList}>
                <NavMenuItems items={subMenu.subMenuItems} />
              </div>
            </NavMenuSection>
          ))}
        </div>
      </div>
    )
  }

  // 複数カラムの場合
  const columns: Column[] = subMenus.reduce((acc, subMenu) => {
    const column = subMenu?.column || 0
    if (!acc[column]) {
      acc[column] = {
        key: `column-${column}`,
        subMenus: [],
      }
    }
    acc[column].subMenus.push(subMenu)
    return acc
  }, [] as Column[])

  return (
    <div className={styles.container}>
      {columns.map((column) => (
        <div key={column.key} className={styles.column}>
          {column.subMenus.map((subMenu) => (
            <NavMenuSection key={subMenu.name} title={subMenu.name}>
              <div className={styles.itemList}>
                <NavMenuItems items={subMenu.subMenuItems} />
              </div>
            </NavMenuSection>
          ))}
        </div>
      ))}
    </div>
  )
})
