import { FC } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '@/utils'
import { DesktopHeaderRightSignInGroup } from '@components-v2/molecules/DesktopHeaderRightSignInGroup'
import { GlobalDesktopHeaderAccountMenu } from '@components-v2/organisms/GlobalDesktopHeaderAccountMenu'
import { DesktopHeaderRightLanguageSelection } from '@/componentsV2/molecules/DesktopHeaderRightLanguageSelection'
import styles from './index.module.scss'

export const GlobalDesktopHeaderRight: FC = observer(() => {
  const { viewer } = useStores()
  return (
    <div className={styles.container}>
      <DesktopHeaderRightLanguageSelection />
      {viewer.isSignedIn ? <GlobalDesktopHeaderAccountMenu /> : <DesktopHeaderRightSignInGroup />}
    </div>
  )
})
