import { FC } from 'react'
import {
  GlobalDesktopHeaderNavMenuDropdown,
  GlobalDeskTopNavSubMenu,
} from '@components-v2/molecules/GlobalDesktopHeaderNavMenuDropdown'
import { observer } from 'mobx-react'
import styles from './index.module.scss'

export type GlobalDeskTopNavMenuItem = {
  name: string
  isMultipleColumns: boolean
  subMenus: GlobalDeskTopNavSubMenu[]
}

type Props = GlobalDeskTopNavMenuItem

export const GlobalDesktopHeaderNavMenu: FC<Props> = observer(({ name, subMenus, isMultipleColumns }) => {
  return (
    <div className={styles.container}>
      <div className={styles.name}>{name}</div>
      <div className={styles.dropDown}>
        <GlobalDesktopHeaderNavMenuDropdown subMenus={subMenus} isMultipleColumns={isMultipleColumns} />
      </div>
    </div>
  )
})
