import Link from 'next/link'
import { FC, ReactNode } from 'react'
import { sanitizeUrl } from '@/utils'
import { observer } from 'mobx-react'
import styles from './index.module.scss'

type Props = {
  label: string
  description: string
  href: string
  iconSvgNode: ReactNode
  isExternal: boolean
}

export const NavMenuItem: FC<Props> = observer(({ label, description, href, iconSvgNode, isExternal }) => {
  if (isExternal) {
    return (
      <a className={styles.container} href={sanitizeUrl(href)} target='_blank' rel='noreferrer'>
        <div className={styles.left}>
          <div className={styles.svg}>{iconSvgNode}</div>
        </div>
        <div className={styles.right}>
          <div className={styles.title}>{label}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </a>
    )
  }
  return (
    <Link href={sanitizeUrl(href)}>
      <a className={styles.container}>
        <div className={styles.left}>
          <div className={styles.svg}>{iconSvgNode}</div>
        </div>
        <div className={styles.right}>
          <div className={styles.title}>{label}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </a>
    </Link>
  )
})
