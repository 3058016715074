import { FC } from 'react'
import { useRouter } from 'next/router'
import {
  GlobalDesktopHeaderNavMenu,
  GlobalDeskTopNavMenuItem,
} from '@components-v2/molecules/GlobalDesktopHeaderNavMenu'
import BriefCaseSvg from '@/public/images/common/briefcase-01_v2.svg'
import FileSvg from '@/public/images/common/file-02_v2.svg'
import PenSvg from '@/public/images/common/pen-tool-01_v2.svg'
import RocketSvg from '@/public/images/common/rocket-01_v2.svg'
import PeopleSvg from '@/public/images/common/users-01_v2.svg'
import BuildingSvg from '@/public/images/common/building-01_v2.svg'
import CoinsHandSvg from '@/public/images/common/coins-hand_v2.svg'
import PieChartSvg from '@/public/images/common/untitled-ui-icons/pie-chart-02.svg'
import AwardSvg from '@/public/images/common/untitled-ui-icons/award-01.svg'
import GiftSvg from '@/public/images/common/untitled-ui-icons/gift-01.svg'
import CurrencyYenSvg from '@/public/images/common/untitled-ui-icons/currency-yen-circle.svg'
import GraduationHatSvg from '@/public/images/common/untitled-ui-icons/graduation-hat-02.svg'
import MessageSmileSvg from '@/public/images/common/untitled-ui-icons/message-smile-square.svg'
import { useTranslation } from 'next-i18next'
import { observer } from 'mobx-react'
import { GlobalDeskTopNavSubMenu } from '@/componentsV2/molecules/GlobalDesktopHeaderNavMenuDropdown'
import { GlobalDeskTopNavMenuItemChild } from '@/componentsV2/molecules/NavMenuItems'
import styles from './index.module.scss'

const MENU_KEY = {
  ANGELS: 'angels',
  JOB_SEEKERS: 'job seekers',
  ARTICLES: 'articles',
  FUND_MANAGERS: 'fund managers',
  STARTUPS: 'startups',
  NONE: 'none',
}
Object.freeze(MENU_KEY)

type MenuItem = GlobalDeskTopNavMenuItem & {
  key: string
}

export const GlobalDesktopHeaderNavigation: FC = observer(() => {
  const router = useRouter()
  const { t } = useTranslation(['global'])
  const rawMenuItems: MenuItem[] = [
    {
      key: MENU_KEY.ARTICLES,
      name: t('ニュース'),
      isMultipleColumns: false,
      subMenus: [
        {
          name: t('編集部投稿'),
          subMenuItems: [
            {
              label: t('特集記事'),
              description: t('編集部が投稿したスタートアップ動向の記事をチェック'),
              href: '/articles/categories/features',
              iconSvgNode: <FileSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
            {
              label: t('インタビュー記事'),
              description: t('編集部が投稿したインタビュー記事をチェック'),
              href: '/articles/categories/interviews',
              iconSvgNode: <FileSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
          ],
        },
        {
          name: t('コミュニティ投稿'),
          subMenuItems: [
            {
              label: t('ストーリー'),
              description: t('見識とつながりを深める、コミュニティの投稿を探そう'),
              href: '/articles/categories/stories',
              iconSvgNode: <PenSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
          ],
        },
      ],
    },
    {
      key: MENU_KEY.STARTUPS,
      name: t('スタートアップ向け'),
      isMultipleColumns: router.locale === 'ja', // 日本語のみメニューが長くなりすぎるので複数カラム
      subMenus: [
        {
          name: t('資金調達'),
          column: 0,
          subMenuItems: [
            {
              label: t('エンジェル投資家を探す'),
              description: t('成長を支援するパートナー、エンジェル投資家を見つけよう'),
              href: '/angels',
              iconSvgNode: <PeopleSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
            {
              label: t('ベンチャーキャピタルを探す'),
              description: t('資金調達のパートナーとして最適なベンチャーキャピタルを見つけよう'),
              href: '/vcs',
              iconSvgNode: <BuildingSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
          ],
        },
        {
          name: t('イグジット'),
          column: 0,
          subMenuItems: [
            {
              label: t('M&Aで会社を売却する'),
              description: t('成果を手にするためのM&Aをスムーズに実現しよう'),
              href: `https://ma.protocol.ooo/${router.locale === 'ja' ? '1' : '1-1'}`,
              iconSvgNode: <CoinsHandSvg viewBox='0 0 24 24' />,
              isExternal: true,
            },
          ],
        },
      ],
    },
    {
      key: MENU_KEY.ANGELS,
      name: t('エンジェル投資家向け'),
      isMultipleColumns: false,
      subMenus: [
        {
          name: t('ソーシング'),
          subMenuItems: [
            {
              label: t('スタートアップを探す'),
              description: t('未来の成功を共に築く、魅力的なスタートアップを見つけよう'),
              href: '/startups',
              iconSvgNode: <RocketSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
            {
              label: t('優良なスタートアップへ投資する'),
              description: t('成長が見込まれるスタートアップへの特別な投資機会の案内を受け取る'),
              href: 'https://prosupporters.protocol.ooo/protocol-capital',
              iconSvgNode: <AwardSvg viewBox='0 0 24 24' />,
              isExternal: true,
            },
            {
              label: t('エンジェル投資家コミュニティに参加する'),
              description: t('エンジェル投資家同士の出会いとスタートアップとの出会いを加速する'),
              href: 'https://propitch.protocol.ooo/angelcommunity',
              iconSvgNode: <PeopleSvg viewBox='0 0 24 24' />,
              isExternal: true,
            },
          ],
        },
        {
          name: t('ディール・ポートフォリオ管理'),
          subMenuItems: [
            {
              label: t('投資管理クラウドを使う（β版）'),
              description: t('投資管理クラウドを活用して効率的な投資戦略の実行と資産の成長を目指しましょう'),
              href: 'https://prosupporters.protocol.ooo/investorcloud',
              iconSvgNode: <PieChartSvg viewBox='0 0 24 24' />,
              isExternal: true,
            },
          ],
        },
      ],
    },
    {
      key: MENU_KEY.FUND_MANAGERS,
      name: t('ファンドマネージャー向け'),
      isMultipleColumns: false,
      subMenus: [
        {
          name: t('ソーシング'),
          subMenuItems: [
            {
              label: t('スタートアップを探す'),
              description: t('未来の成功を共に築く、魅力的なスタートアップを見つけよう'),
              href: '/startups',
              iconSvgNode: <RocketSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
            {
              label: t('優良なスタートアップへ投資する'),
              description: t('成長が見込まれるスタートアップへの特別な投資機会の案内を受け取る'),
              href: 'https://prosupporters.protocol.ooo/protocol-capital',
              iconSvgNode: <AwardSvg viewBox='0 0 24 24' />,
              isExternal: true,
            },
          ],
        },
        {
          name: t('ディール・ポートフォリオ管理'),
          subMenuItems: [
            {
              label: t('投資管理クラウドを使う（β版）'),
              description: t('投資管理クラウドを活用して効率的な投資戦略の実行と資産の成長を目指しましょう'),
              href: 'https://prosupporters.protocol.ooo/investorcloud',
              iconSvgNode: <PieChartSvg viewBox='0 0 24 24' />,
              isExternal: true,
            },
          ],
        },
      ],
    },
    {
      key: MENU_KEY.JOB_SEEKERS,
      name: t('求職者向け'),
      isMultipleColumns: false,
      subMenus: [
        {
          name: t('求人'),
          subMenuItems: [
            {
              label: t('求人を探す'),
              description: t('次なるキャリアを築くための求人募集を見つけよう'),
              href: '/jobs',
              iconSvgNode: <BriefCaseSvg viewBox='0 0 24 24' />,
              isExternal: false,
            },
          ],
        },
      ],
    },
  ]

  const contactSalesMenuItems: GlobalDeskTopNavMenuItemChild = {
    label: t('グロースについて相談する'),
    description: t('資金調達、販路拡大、デザイン、開発等お困りのことがありましたらいつでもお気軽にご連絡ください'),
    href:
      router.locale === 'ja'
        ? process.env.NEXT_PUBLIC_CONTACT_SALES_JA_URL
        : process.env.NEXT_PUBLIC_CONTACT_SALES_EN_URL,
    iconSvgNode: <MessageSmileSvg viewBox='0 0 24 24' />,
    isExternal: true,
  }

  const startUpEnglishMenuItem: GlobalDeskTopNavSubMenu[] = [
    {
      name: t('その他'),
      column: 0,
      subMenuItems: [contactSalesMenuItems],
    },
  ]

  const startUpJapaneseMenuItem: GlobalDeskTopNavSubMenu[] = [
    {
      name: t('その他'),
      column: 1, // 日本語メニューでは「その他」は2カラム目に表示
      subMenuItems: [
        {
          label: t('会員限定特典'),
          description: t('独占的な特典が待つ、会員限定の特別な世界へようこそ'),
          href: 'https://prosupporters.protocol.ooo/',
          iconSvgNode: <GiftSvg viewBox='0 0 24 24' />,
          isExternal: true,
        },
        {
          label: t('補助金を探す'),
          description: t('成長とイノベーションを支える、多彩な補助金情報を見つけよう '),
          href: '/subsidies',
          iconSvgNode: <CurrencyYenSvg viewBox='0 0 24 24' />,
          isExternal: false,
        },
        {
          label: t('メンターを探す'),
          description: t('経験豊かなメンターとの出会いで成長を加速しよう'),
          href: 'https://prosupporters.protocol.ooo/protocolmentor',
          iconSvgNode: <GraduationHatSvg viewBox='0 0 24 24' />,
          isExternal: true,
        },
        contactSalesMenuItems,
      ],
    },
  ]

  const menuItems = rawMenuItems.map((item) => {
    if (item.key === MENU_KEY.STARTUPS && router.locale === 'ja') {
      // 日本語専用メニューを追加
      return {
        ...item,
        subMenus: [...item.subMenus, ...startUpJapaneseMenuItem],
      }
    }
    if (item.key === MENU_KEY.STARTUPS && router.locale === 'en') {
      return {
        ...item,
        subMenus: [...item.subMenus, ...startUpEnglishMenuItem],
      }
    }
    return item
  })

  return (
    <>
      <div className={styles.container}>
        {menuItems.map((item) => {
          return (
            <GlobalDesktopHeaderNavMenu
              key={item.name}
              name={item.name}
              subMenus={item.subMenus}
              isMultipleColumns={item.isMultipleColumns}
            />
          )
        })}
      </div>
    </>
  )
})
