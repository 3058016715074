import { FC } from 'react'
import styles from './index.module.scss'

export const DropDownListTextHeader: FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
