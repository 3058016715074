import { FC, PropsWithChildren } from 'react'
import { observer } from 'mobx-react'
import styles from './index.module.scss'

type Props = {
  title: string
}

export const NavMenuSection: FC<PropsWithChildren<Props>> = observer(({ title, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.head}>{title}</div>
      <div className={styles.content}>{children}</div>
    </div>
  )
})
