import { FC } from 'react'
import { ICompanyBase, IMyCompanyBase } from '@/types'
import { getCompanyLogo } from '@/utils'
import { observer } from 'mobx-react'
import { DropDownMenuItemWithImage } from '@/componentsV2/molecules/DropDownMenuItemWithImage'

type Props = {
  companies: (ICompanyBase | IMyCompanyBase)[]
}

export const DropDownListCompanyHeaders: FC<Props> = observer(({ companies }) => {
  return (
    <>
      {companies.map((company) => (
        <DropDownMenuItemWithImage
          key={company?.id}
          imageSrc={getCompanyLogo(company)}
          label={company?.name}
          href={`/companies/${company.slug}/account/profile`}
        />
      ))}
    </>
  )
})
