import { FC } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  onClick: () => void
  isOpen: boolean
  backGroundColor?: 'transparent' | 'gray'
}

export const OutSideOverlay: FC<Props> = ({ onClick, isOpen, backGroundColor = 'transparent' }) => {
  const className = classNames(
    styles.container,
    {
      [styles.transparent]: backGroundColor === 'transparent',
      [styles.gray]: backGroundColor === 'gray',
    },
    {
      [styles.hidden]: !isOpen,
    }
  )

  return (
    <div className={className} role='button' tabIndex={0} onClick={onClick} onKeyPress={onClick}>
      OutSideOverlay
    </div>
  )
}
