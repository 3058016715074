import { FC } from 'react'
import Link from 'next/link'
import { Logo } from '@components-v2/atoms/Logo'
import { GlobalMobileHeaderServiceMenu } from '@components-v2/organisms/GlobalMobileHeaderServiceMenu'
import { GlobalMobileHeaderAccountMenu } from '@components-v2/organisms/GlobalMobileHeaderAccountMenu'
import styles from './index.module.scss'

export const GlobalMobileHeader: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <GlobalMobileHeaderServiceMenu />
      </div>
      <div className={styles.center}>
        <Link href='/'>
          <a className={styles.logo}>
            <Logo />
          </a>
        </Link>
      </div>
      <div className={styles.right}>
        <GlobalMobileHeaderAccountMenu />
      </div>
    </div>
  )
}
