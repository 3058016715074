import { FC, PropsWithChildren } from 'react'
import { observer } from 'mobx-react'
import styles from './index.module.scss'

type Props = {
  toggleDropDown?: () => void
}

export const DropdownMenu: FC<PropsWithChildren<Props>> = observer(({ toggleDropDown, children }) => {
  return (
    <div className={styles.container} role='button' tabIndex={0} onClick={toggleDropDown} onKeyPress={toggleDropDown}>
      {children}
    </div>
  )
})
