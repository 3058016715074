import { FC, useState, MouseEvent, KeyboardEvent, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { OutSideOverlay } from '@components-v2/atoms/OutSideOverlay'
import IconSvg from '@/public/images/common/chevron-down_v2.svg'
import styles from './index.module.scss'

type MenuItem = {
  name: string
  ISO6391: string
}

export const LanguageSelection: FC = observer(() => {
  const router = useRouter()
  const [isOpen, setIsOpen] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const toggleIsOpen = (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const languageItems: MenuItem[] = [
    {
      name: 'English',
      ISO6391: 'en',
    },
    {
      name: '日本語',
      ISO6391: 'ja',
    },
  ]

  const currentLanguage = languageItems.find((i) => i.ISO6391 === router.locale)

  // router.query に値が入ったか確認
  useEffect(() => {
    if (router.isReady) {
      setIsReady(true)
    }
  }, [router.isReady])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div
          className={classNames(styles.currentLanguage)}
          role='button'
          tabIndex={0}
          onClick={toggleIsOpen}
          onKeyPress={toggleIsOpen}
        >
          <div className={styles.name}>{currentLanguage.name}</div>
          <div className={styles.icon}>
            <IconSvg viewBox='0 0 24 24' />
          </div>
        </div>
        {isOpen && (
          <div className={styles.selectableLanguages}>
            {/* TODO ここはデザインになかったので、あとで調整 */}
            {isReady &&
              languageItems.map((li) => (
                <a key={li.ISO6391} href={`/${li.ISO6391}${router.asPath}`}>
                  <div
                    className={classNames(styles.selectableLanguages__item, {
                      [styles.selectedLanguage]: li === currentLanguage,
                    })}
                  >
                    {li.name}
                  </div>
                </a>
              ))}
          </div>
        )}
        <OutSideOverlay onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} />
      </div>
    </div>
  )
})
