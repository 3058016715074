import { FC, ReactNode } from 'react'
import Link from 'next/link'
import { sanitizeUrl } from '@/utils'
import { observer } from 'mobx-react'
import styles from './index.module.scss'

type Props = {
  type?: 'link' | 'button'
  iconNode?: ReactNode
  href?: string
  text: string
  isExternal?: boolean
  onClick?: () => void
}

export const DropDownListItem: FC<Props> = observer(
  ({ type = 'link', iconNode = null, href, text, onClick, isExternal = false }) => {
    if (type === 'button') {
      return (
        <button type='button' className={styles.buttonContainer} onClick={onClick}>
          <div className={styles.content}>
            {iconNode && <div className={styles.icon}>{iconNode}</div>}
            <div className={styles.text}>{text}</div>
          </div>
        </button>
      )
    }

    if (isExternal) {
      return (
        <a href={sanitizeUrl(href)} target='_blank' rel='noreferrer' className={styles.linkContainer}>
          <div className={styles.content}>
            {iconNode && <div className={styles.icon}>{iconNode}</div>}
            <div className={styles.text}>{text}</div>
          </div>
        </a>
      )
    }

    return (
      <Link href={sanitizeUrl(href)}>
        <a className={styles.linkContainer}>
          <div className={styles.content}>
            {iconNode && <div className={styles.icon}>{iconNode}</div>}
            <div className={styles.text}>{text}</div>
          </div>
        </a>
      </Link>
    )
  }
)
